/* 共用 */
.w-full {
  width: 100%;
}
.w-1 {
  width: 1rem;
}
.w-2 {
  width: 2rem;
}
.w-3 {
  width: 3rem;
}
.w-4 {
  width: 4rem;
}
.w-5 {
  width: 5rem;
}
.w-6 {
  width: 6rem;
}
.w-7 {
  width: 7rem;
}
.w-8 {
  width: 8rem;
}
.w-9 {
  width: 9rem;
}
.w-10 {
  width: 10rem;
}
.w-12 {
  width: 12rem;
}
.w-14 {
  width: 14rem;
}
.w-16 {
  width: 16rem;
}
.w-18 {
  width: 18rem;
}
.w-20 {
  width: 20rem;
}
.w-22 {
  width: 22rem;
}
.w-24 {
  width: 24rem;
}
.w-26 {
  width: 26rem;
}
.h-full {
  height: 100%;
}
.h-1 {
  height: 1rem;
}
.h-2 {
  height: 2rem;
}
.h-3 {
  height: 3rem;
}
.h-4 {
  height: 4rem;
}
.h-5 {
  height: 5rem;
}
.h-6 {
  height: 6rem;
}
.h-7 {
  height: 7rem;
}
.h-8 {
  height: 8rem;
}
.h-9 {
  height: 9rem;
}
.h-10 {
  height: 10rem;
}
.h-12 {
  height: 12rem;
}
.h-14 {
  height: 14rem;
}
.h-16 {
  height: 16rem;
}
.h-18 {
  height: 18rem;
}
.h-20 {
  height: 20rem;
}
.h-22 {
  height: 22rem;
}
.h-24 {
  height: 24rem;
}
.h-26 {
  height: 26rem;
}
.h-28 {
  height: 28rem;
}
.h-30 {
  height: 30rem;
}

.m-1 {
  margin: 1rem;
}
.m-2 {
  margin: 2rem;
}
.m-3 {
  margin: 3rem;
}
.m-4 {
  margin: 4rem;
}
.m-5 {
  margin: 5rem;
}
.mt--2{
  margin-top: -2rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.ml-1 {
  margin-left: 1rem;
}
.ml-2 {
  margin-left: 2rem;
}
.ml-3 {
  margin-left: 3rem;
}
.mr-1 {
  margin-right: 1rem;
}
.mr-2 {
  margin-right: 2rem;
}
.mr-3 {
  margin-right: 3rem;
}

.p-1 {
  padding: 1rem;
}
.p-2 {
  padding: 2rem;
}
.p-3 {
  padding: 3rem;
}
.p-4 {
  padding: 4rem;
}
.p-5 {
  padding: 5rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pl-1 {
  padding-left: 1rem;
}
.pl-2 {
  padding-left: 2rem;
}
.pr-1 {
  padding-right: 1rem;
}
.pr-2 {
  padding-right: 2rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.pv-1 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.pv-2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.ph-1 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.ph-2 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.bg-color-01 {
  background-color: #d6c093;
}
.bg-color-02 {
  background-color: #1e211d;
}
.bg-color-03 {
  background-color: #fff;
}
.bg-color-04 {
  background-color: #E3A365;
}
.bg-color-05 {
  background-color: #62B385;
}
.bg-color-06 {
  background-color: #4FA239;
}
.bg-color-07 {
  background-color: #5512B0;
}
.bg-color-07 {
  background-color: #d6c09389 !important;
}

.color-01 {
  color: #fff;
}
.color-02 {
  color: #e3e3e3;
}
.color-03 {
  color: #929292;
}
.color-04 {
  color: #535a6c;
}
.color-05 {
  color:#C7CAD2;
}
.color-06 {
  color:#818181;
}
.color-07 {
  color:#1777FF;
}
.color-up {
  color:#545D70;
}
.color-down {
  color:#E05D5E;
}
.color-padding {
  color: #545D70;
}
.color-pass {
  color:#6FC546;
}
.color-reject {
  color:#EE471D;
}
.color-padding {
  color: #929292;
}
.color-reviewing {
  color: #E89E42;
}
.color-danger {
  background-color: #F96868;
  color: #fff;
}
.color-warn { 
  background-color: #F2A654;
  color: #fff;
}
.color-safe { 
  background-color: #DCDEE2;
  color: #838997;
}
.color-up-0 {
  color:#6FC546;
}
.color-down-0 {
  color:#E05D5E;
}
.color-withdraw-pass {
  background-color: #4A8AE9;
  color:#fff;
}
.color-withdraw-padding-review {
  background-color: #61AECE;
  color: #fff;
}
.color-withdraw-padding {
  background-color: #85181A;
  color: #fff;
}
.color-withdraw-reviewing {
  background-color: #E89E42;
  color: #fff;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-start {
  display: flex;
  justify-content: start;
}
.flex-end {
  display: flex;
  justify-content: end;
}
.flex-between {
  display: flex;
  justify-content: space-between;
}
.display-none {
  display: none;
}

.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}
.text-start {
  text-align: start;
}

.border-none {
  border: none;
}
.border-01 {
  border: 1px solid;
}
.border-02 {
  border: 2px solid;
}
.border-03 {
  border: 3px solid;
}
.border-04 {
  border-left: .2px solid #c7cad28f;
  border-right: .2px solid #c7cad28f;
}

.border-rd-01 {
  border-radius: 5px;
}
.border-rd-02 {
  border-radius: 10px;
}
.border-rd-03 {
  border-radius: 15px;
}
.border-rd-04 {
  border-radius: 20px;
}

.size-10 {
  font-size: 10px;
}
.size-12 {
  font-size: 12px;
}
.size-14 {
  font-size: 14px;
}
.size-16 {
  font-size: 16px;
}
.size-18 {
  font-size: 18px;
}
.size-20 {
  font-size: 20px;
}
.size-22 {
  font-size: 22px;
}
.size-24 {
  font-size: 24px;
}
.size-26 {
  font-size: 26px;
}
.size-28 {
  font-size: 28px;
}
.size-30 {
  font-size: 30px;
}

.font-w-lg {
  font-weight: 900;
}
.font-w-md {
  font-weight: 700;
}
.font-w-sm {
  font-weight: 500;
}

/* 登入頁面 */
.login-card {
  width: 520px;
  padding: 145px 89px 40px;
  position: relative;
  border-radius: 22px;
}
.login-card-bg {
  padding: 5px;
  background: rgba(235, 234, 234, 0.5);
  border-radius: 24px;
  position: relative;
}
.login-card .ant-card-body {
  padding: 0;
}
.login-card .ant-card-body .ant-select-single {
  height: 44px;
}
.login-card .ant-card-body .ant-input-affix-wrapper{
  padding: 10px 11px;
}
.login-background-image {
  background-image: url("../image/login-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100% !important;
  height: 100vh;
}
.login-title.ant-form-item {
  margin-bottom: 13px !important;
}
.login-title .ant-form-item-control-input {
  min-height: auto;
}
.login-logo {
  border-radius: 100%;
  background: #D3D3D3;
}
.login-box {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translate(-50%, -35%);
  background-image: url("../image/login-logo.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  width: 226px;
  height: 227px;
}
.login-btn-wrap .ant-row {
  width: 100%;
}
.login-btn {
  background-image: url("../image/login-btn.png") !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  height: 52px;
  border: none;
  box-shadow: none;
  transition: all 0.5s;
}
.login-btn:hover {
  color: #fff !important;
  opacity: 0.8;
}
.login-title p {
  margin: 0;
  font-size: 16px;
  line-height: 1.375;
}
.login-card .inputMobile,
.login-card .inputVerifyMobile {
  padding: 10px 11px;
}
.login-card .send-message[disabled],
.login-card .send-verifyMobile[disabled] {
  color: #d7d7d7;
}
.login-card .send-message[disabled]:hover,
.login-card .send-verifyMobile[disabled]:hover {
  opacity: 1;
  color: #d7d7d7 !important;
}
@media only screen and (max-width: 767px) {
  .login-card {
    padding: 85px 21px 34px;
    width: 100%;
  }
  .login-box {
    width: 130px;
    height: 130px;
  }
  .login-card-bg {
    max-width: 325px;
    width: 90%;
  }
  .login-background-image {
    background-image: url("../image/login-bg_mobile.png");
  }
  .login-card .ant-card-body .ant-select-single {
    height: 40px;
  }
  .login-card .ant-card-body .ant-input-affix-wrapper,
  .login-card .inputMobile,
  .login-card .inputVerifyMobile{
    padding: 8px 11px;
  }
  .login-title p {
    font-size: 14px;
    line-height: 1.571428;
  }
}

/* Menu */
.item-style {
  display: flex;
  align-items: center;
}

/* Edit Nick Name Input */
.edit-nick-input {
  padding-left: 5px !important;
  padding-right: 5px !important;
  background: #ffffff20 !important;
}
.edit-nick-input:focus {
  border-color: #fff !important;
}
.edit-nick-input:hover {
  border-color: #fff !important;
}
.edit-nick-input-button {
  background: #fff;
  border-radius: 3px;
  width: 20px;
  height: 20px;
}

/* 首頁 */
.home-checkbox {
  border: 1px solid #e3e3e3;
}
.online-num {
  margin-left: 10px;
  background: #4a89e9;
  padding: 1px 20px;
  font-size: 12px;
  color: #fff;
  border-radius: 50px;
}
.card-shadow {
  box-shadow: 0px 2px 10px 2px #00000020;
}
.card-none {
  background: none;
  border: none;
}
.card-bg-01 {
  background: linear-gradient(135deg, #2DBDE0 0%, #317CE0 100%);
}
.card-bg-02 {
  background: linear-gradient(135deg, #D974FF 0%, #7D2BFF 100%);
}
.card-bg-03 {
  background: linear-gradient(135deg, #00D6AF 0%, #00BDB2 100%);
}
.card-bg-04 {
  background: linear-gradient(135deg, #FFC480 0%, #FF8730 100%);
}
.card-bg-05 {
  background: linear-gradient(135deg, #C8C7C7 0%, #9A9A9A 100%);
}
.card-bg-06 {
  background: linear-gradient(135deg, #FF90BF 0%, #FF4286 100%);
}

/* Color */
.require {
  color: red;
  margin-right: 1px;
}

/* Notification */
.notification {
  background: #F5F7FA !important;
}

/* Table */
.ant-table-thead .ant-table-cell {
  background: #F5F7FA !important;
}
.ant-table-summary .ant-table-cell {
  background: #F5F7FA !important;
}
.ant-table-cell {
  font-size: 12px !important;
}

/* Descriptions */
.ant-descriptions-item-label {
  background: #F5F7FA !important;
}

/* Drawer */
.ant-drawer-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-top: 0 !important;
}
.ant-list-item {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.ant-list-item:hover {
  background: #fafafa;
  cursor: pointer;
}

/* Menu */
.ant-menu-item-selected {
  background-color: #1E211D !important;
}
.ant-menu-submenu-selected {
  background-color: #1E211D !important;
}
.ant-menu {
  z-index: 99999 !important;
}
/* Form */
.ant-form-item {
  margin-bottom: 0px !important;
}
.ant-form-item-label {
  padding: 1px !important;
}

/* Content */
.ant-layout-content {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  padding-bottom: 2rem !important;
  padding-top: 0 !important;
}

/* Button */
.ant-btn-link {
  font-size: 12px !important;
  padding: 5px !important;
}
.color-up-tag {
  background-color: #6FC546;
  color:#fff;
  padding: 2px 5px;
  border-radius: 5px;
   display: inline-block;
}
.color-down-tag {
  background-color: #A7372D;
  color:#fff;
  padding: 2px 5px;
  border-radius: 5px;
  display: inline-block;
}
.color-tag {
  background-color: #e3e3e3;
  padding: 2px 5px;
  border-radius: 5px;
  display: inline-block;
}
.mr-0 {
  margin-right: 0;
}
.p-0 {
  padding: 0;
}

/* chat */
.rce-mbox {
  max-width: 50% !important;
  margin-bottom: 10px !important;
}

.form-search {
  background: #f9f9f9;
  width: calc(100% + 4rem);
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 1rem 2rem;
}

.color-03 .ant-menu-submenu-selected > .ant-menu-submenu-title,
.color-03 .ant-menu-item:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected):hover,
.color-03 .ant-menu-item-selected {
  color: #929292 !important;
}

.ant-btn-background-ghost:hover {
  background: #1e211d !important; 
  color: #fff !important;
  border-color: #fff !important;
}
.menu {
  position: sticky;
  top: 0;
  z-index: 1002!important;
}
.menu-01 .ant-menu-submenu .ant-menu-item {
  padding: 8px 16px 8px 32px !important;
  min-height: 40px;
  height: auto;
}
.menu-01 .ant-menu-submenu .ant-menu-item .ant-menu-title-content {
  white-space: pre-line;
  line-height: 1.5;
}
.excel-btn {
  border: 0;
  color: #fff;
  background-color: #52c41a;
}
.excel-btn:hover {
  color: #fff !important;
  background-color: #76de44 !important;
}

.nav-logo {
  cursor: pointer; 
  width: auto;
}
.top-menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.time-zone{
  line-height: 1rem;
  cursor: default!important;
  color:#FFFFFFA6!important;
  display: flex !important;
  align-items: center;
}

.ant-message {
  z-index: 10000;
}

.ant-drawer{
  z-index: 1100!important;
}

.ant-select-dropdown {
  z-index: 1001!important;
}

.ant-picker-dropdown {
  z-index: 1001!important;
}
.ant-layout-sider.bg-color-02 {
  background-color: #1e211d !important;
}

.ant-modal-body {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.ant-popover {
  z-index: 1000 !important;
}

/*z-index排序請看這*/
/*.ant-message 和.ant-drawer > */
/*.menu >*/
/*.ant-select-dropdown 和.ant-picker-dropdown>*/
/*.ant-popover 和 antd的Modal預設1000*/

.custom-hover-button:hover {
  background-color: #fff!important;
}

/* 編輯器table顯示邊線 */
figure.table table {
  border-spacing: 0;
  border-collapse: collapse;
  td {
    border: 1px solid #bfbfbf;
    min-width: 1.5em;
    padding: .4em;
  }
}

.date-radio-mobile .ant-radio-button-wrapper {
  padding-inline: 12.5px;
}